<template>
  <div class="app">
    <div class="container">
      <div class="left">
        <img src="@/assets/logo.png" />
        <span @click="$router.push('/')">魔力漏斗</span>
      </div>
      <nav class="right">
        <div>
          <!-- <p><img src="@/assets/TEL.png" /></p> -->
          <!-- <span>400-810-6651</span> -->
        </div>
        <!-- <a class="downBtn" href="http://42.81.139.207:8088/funnel/#/register3" target="_black">立即试用</a> -->
        <!-- <a class="downBtn" href="http://funnel.zhiyaojituan.net/#/register3" target="_black">立即试用</a> -->
        <a class="downBtn" href="http://funnel.zhiyaojituan.net/funnel" target="_black">立即试用</a>
      </nav>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {},
  created() {},
  mounted() {}
}
</script>
<style lang="less" scoped>
.app {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 999;
}
.container {
  width: 1060px;
  height: 70px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.downBtn {
  width: 160px;
  height: 40px;
  border: 1px solid #4762f7;
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
  margin-left: 34px;
  text-decoration: none;
  color: #4762f7;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
}
.left {
  display: flex;
  span {
    font-size: 24px;
    font-family: PingFang SC, sans-serif;
    font-weight: bold;
    color: #333333;
    line-height: 36px;
    margin-left: 17px;
  }
}
.right {
  display: flex;
  align-items: center;
  span {
    width: 130px;
    height: 14px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
  }
  div {
    display: flex;
    p {
      width: 28px;
      height: 28px;
      margin: 0 7px 0 0;
      background-color: #4762f7;
      border-radius: 50%;
      text-align: center;
    }
  }
}
</style>
