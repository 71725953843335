import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { WOW } from 'wowjs'
import animated from 'animate.css';

// Vue.use(animated);
Vue.use(ElementUI)

Vue.prototype.$wow = new WOW({
  boxClass: 'wow', // default
  animateClass: 'animated', // default
  offset: 100, // default
  mobile: true, // default
  live: false, 
  // live为true时，控制台会提示：MutationObserver is not supported by your browser. & WOW.js cannot detect dom mutations, please call .sync() after loading new content.

 
  callback: function (box) {
    // console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
  }
});

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
