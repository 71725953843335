<template>
  <div id="app">   
    <Header />
    <router-view></router-view>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
}
</script>

<style>
body{
  background-color: #F4F6FD;
}
a button{   
  cursor: pointer;
}
em, i {
    font-style: normal;
}


</style>
