import Vue from "vue";
import VueRouter from "vue-router";
//解决3.0版本重复点击的bug
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        redirect: "/index",
    },
    {
        path: "/index",
        name: "index",
        component: () =>
            import ("@/views/homePage"),
    },    
];

const router = new VueRouter({
    // mode: "history",
    mode: "hash",
    base: process.env.BASE_URL,
    routes,
});

export default router;