<template>
  <div class="footer">
      <!-- <p class="info">Copyright © 2021 北京科技有限公司 津ICP备16045432号-4 </p> -->
      <p class="info">Copyright 2018 . All Rights Reserved. 天津智尧网络通信技术有限公司 津ICP备19010183号 </p>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {},
  created() {},
  mounted() {}
}
</script>
<style scoped>
.footer {
  width: 100%;
  height: 124px;
  background: #2a3142;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info {
  height: 17px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 36px;
  opacity: 0.3;
}
</style>
